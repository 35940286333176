<template>
    <div class="date-range" :class="{'sm': size == 'sm'}">
        <el-date-picker
            size="small"
            align="left"
            type="daterange"
            value-format="yyyy-MM-dd"
            :editable="false"
            :clearable="false"
            range-separator="-"
            class="performance-date-input"
            :class="customBorder ? 'custom-border' : ''"
            v-model="rangeDate"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleChange"
            :picker-options="rangeDateOption"
        ></el-date-picker>
        <div class="date-input-icon"></div>
    </div>
</template>

<script>
import moment from 'moment';
const YESTODAY = moment().subtract(1, 'days').format('YYYY-MM-DD');
const TODAY = moment().format('YYYY-MM-DD');
const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
const dataRangeData = {
                startDate: lastMonth,
                endDate: TODAY
            };

export default {
    name: "date-range-picker",
    data() {
        return {
            rangeDate: [dataRangeData.startDate || YESTODAY, dataRangeData.endDate || TODAY],
            rangeDateOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '今年',
                    onClick(picker) {
                        const start = moment().format('YYYY') + '-01-01';
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近半年',
                    onClick(picker) {
                        const start = moment().subtract(6, 'months').format('YYYY-MM-DD');
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一年',
                    onClick(picker) {
                        const start = moment().subtract(1, 'years').format('YYYY-MM-DD');
                        const end = moment().format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }
                ]
            },
        }
    },
    props: {
        size: {
            type: String,
            default: ''
        },
        customBorder: {
            type: Boolean,
            default: true
        }
    },
    mounted() {},
    methods: {
        handleChange(dateArray) {
            this.$emit('date-picked', dateArray);
        },
        handleClear() {
            this.rangeDate = [];
        }
    },
}
</script>

<style lang="scss">
.sm{
    line-height: 29px!important;
    .performance-date-input.el-date-editor {
        height: 28px!important;
        .el-range__icon {
            line-height: 22px!important;
        }
        .el-range-separator{
            line-height: 20px!important;
        }
    }
}
.date-range {
    width: 262px;
    line-height: 42px;
    position: relative;
    display: inline-block;
    .performance-date-input.el-date-editor {
        width: auto;
        height: 41px;

        &.custom-border {
            border: 1px solid #d3d3d3!important;
        }
        .el-range__icon {
            line-height: 22px;
        }
        input.el-range-input {
            width: 90px;
            height: auto;
            padding: 0;
        }
        & + .date-input-icon {
            content: '';
            top: 50%;
            display: block;
            position: absolute;
            right: 16px;
            transform: translateY(-50%);
            border-top: 6px solid #a3afb7;
            border-bottom: none;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
        }
        &.is-active {
            & + .date-input-icon {
                border-top: none;
                border-bottom: 6px solid #a3afb7;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
        }
        .el-range-separator{
            line-height: 20px;
        }
    }
}
</style>
